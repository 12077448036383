<template lang="pug">
head-meta(title-page="Оплата мебели из интернет магазина ChiedoHome",
  description="Способы оплаты мебели в интернет магазине ChiedoHome. Работаем с физическими и юридическими лицами, наличная оплата, по карте, безналичные переводы, возможна рассрочка")
payment
</template>

<script>
import Payment from '../components/payment/Payment'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'PaymentPage',
  components: { HeadMeta, Payment }
}
</script>
