<template lang="pug">
article.payment
  .payment__cont.cont
    h1.payment__caption.caption Оплата
    p.payment__text.text-big
      | Выбранный вами товар вы можете оплатить любым удобным для вас способом:
    ul.payment__list
      li.payment__item.text-big
        | Физические лица могут произвести оплату по реквизитам организации или QR-коду, после согласования заказа,
        | менеджер направит вам на электронную почту или мессенджер счет на оплату
      li.payment__item.text-big
        | Юридические лица могут произвести оплату товара только по безналичному расчету. Для выставления счета нам
        | потребуются от Вас реквизиты компании
</template>

<script>
import '@/assets/styles/components/payment.sass'

export default {
  name: 'Payment'
}
</script>
